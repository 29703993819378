@import url("../theme.css");

#main-container {
  // margin-top: 3vh !important;
  color: white;
  position: relative;
}

/* Mobile */
@media only screen and (max-width: 420px) {
  #main-container {
    margin: 5vw;
    max-width: 280px;
  }
}

@media only screen and (max-width: 720px) {
  #main-container {
    margin: 5vw;
    max-width: 420px;
  }
}

/* Mobile */
@media only screen and (min-width: 430px) {
  #main-container {
    margin: auto;
    margin-top: 20px;
  }
}

.back {
  display: flex;
  align-items: center;
  margin-left: -9px; /* 9px is the space to the left of the arrow head */
  max-width: 100px;
  font-size: 1.3rem;
  .left-chevron {
    transform: rotate(90deg);
  }
}

.pointer {
  cursor: pointer;
}
