@import url("../theme.css");

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
  overflow: clip;
  line-height: 1;
  &.border {
    border: 1px solid var(--gray);
    padding: 0.7rem;
    border-radius: 12px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .summary-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: auto;

    .summary-header {
      display: flex;
      justify-content: center;
      color: var(--accentSecondary);
      font-size: 1rem;
      font-weight: 700;
      p {
        margin: 0;
      }
    }

    .summary-content {
      display: flex;
      flex-direction: column;
      .summary-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0.4rem;
        p {
          margin: 0;
          font-size: 1rem;
        }
      }
    }

    .horizontal-rule {
      width: 80%;
    }
  }

  .contentHeader {
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 1.5rem;
    p {
      margin: auto;
    }

    .w-76{
      width: 76%;
    }

    .row-setter{
      width: 100px;
      margin-right: 10px;
    }
  }

  .small-print-container {
    margin-top: 0.5rem;
    p {
      font-size: 0.7rem;
      margin: 0;
      text-align: center;
    }
  }

  .card-content-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .card-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-evenly;
      text-align: start;
      flex: 1;
      max-width: 100%;

      .card-header {
        font-size: 1rem;
        font-weight: 700;
        color: var(--accent);
        margin: 10px 0px 0px 0px;
        text-transform: uppercase;
      }
      .card-body {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 0.9rem;
        line-height: 1.3rem;
        font-weight: 400;
        width: 100%; // may bite us for uncommenting later, but need it for videos

        a {
          svg,
          .icon {
            color: white;
            margin: 0rem;
            width: 5rem;
            height: 3rem;
          }

          .tv-icon{
            color: white;
            margin: 0rem;
            width: 5rem;
            height: 3rem;
            border-radius: 22px;
          }

          .icon {
            border-radius: 22px;
          }
        }

        span{
          // margin-top: 10px;
          display: flex;
          align-items: center;
        }

        .racing-tv-apps {
          display: flex;
          width: 100%;
          justify-content: space-between;
        }
      }

      .ml-30 {
        margin-left: 30px;
      }
    }

    .card-content-align{
      text-align: start;
    }

    .card-central-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;

      .card-price {
        color: var(--accentSecondary);
        font-size: 2rem;
        font-weight: 700;
        margin: 0;
      }

      .card-duration {
        color: var(--white);
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0;
      }
    }

    .card-right-icon {
      flex: 1;
      display: flex;
      justify-content: center;

      path {
        fill: var(--accentSecondary);
      }

      svg {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .w-100{
    width: 100%;
  }
}
