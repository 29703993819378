.portfolio-realex {
  background: #edeceb;
  background-color: rgb(237, 236, 235);
  max-width: 600px;
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
}

#rxp-header {
  width: 100%;
  max-width: 600px;
  height: 56px;
  background: #3a3d46;
  box-shadow: none;
  border: 0;
  overflow: hidden;
  display: block;
  padding-top: 0;
}

#rxp-header .rxp-title {
  padding: 20px 0;
  margin: 0;
  font-size: 1.1em;

  span{
    padding-left: 20px;
  }
}
