.login-header {
  margin-top: 0em;
}

.micro-text {
  font-size: 0.55rem;
  margin-top: 0;
}

.accent-link {
  color: var(--accent);
  text-decoration: none;
  
  &.ml-3{
    margin-left: 3px;
  }
}
