@import url("../theme.css");

.btn-container {
  margin-top: -20px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  padding: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  -webkit-box-shadow: 4px 5px 8px -1px rgba(0, 0, 0, 0.55);
  box-shadow: 4px 5px 8px -1px rgba(0, 0, 0, 0.55);
  &.small {
    height: 30px;
    min-width: 128px;
    border-radius: 3px;
  }
  &.x-small {
    height: 30px;
    min-width: 50px;
    border-radius: 3px;
  }
  &.medium {
    height: 3rem;
    min-width: 15rem;
  }
  &.wide {
    width: 100%;
    height: 3rem;
  }
  &.accent {
    background-color: var(--accent);
    .btn-text {
      color: var(--white);
    }
    .text-size{
      font-size: 13px;
    }
  }
  &.black {
    background-color: #000;
    .btn-text {
      color: var(--white);
    }
  }
  &.padding-3{
    padding: 3px;
  }
  &.accent-secondary {
    background-color: var(--accentSecondary);
    .btn-text {
      color: var(--buttonTextColor);
    }
  }
  &.transparent {
    border: 1px solid var(--gray);
    background-color: var(--transparent);
    .btn-text {
      color: var(--white);
    }
  }
  .btn-text {
    font-weight: 700;
    font-size: var(--mainFontSize);
    user-select: none;
  }

  .right-component {
    margin-left: .5rem;
  }

  &:hover {
    filter: brightness(115%);
  }
  &:active {
    filter: brightness(90%);
  }
  &:disabled {
    opacity: 0.4;
    filter: brightness(80%);
    cursor: not-allowed;
  }
}
