.suggested-addresses {
  border-top: 1px solid var(--gray);
  border-left: 1px solid var(--gray);
  border-right: 1px solid var(--gray);
  background-color: var(--transparent);
  overflow-y: scroll;
  max-height: 13em;
  width: 100%;
  margin-top: -8px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  .suggested-address {
    border-bottom: 1px solid var(--gray);
    padding: 0.5rem;
    cursor: pointer;
    background-color: var(--transparent);
    min-height: 1.5em;
    &:hover {
      background-color: var(--accent);
    }
  }
}
