h1 {
  align-self: flex-start;
  text-align: left;
  font-size: 1.8rem;
  font-weight: 500;

  .bold-heading-accent {
    color: var(--white);
    font-weight: 700;
    &.caps {
      text-transform: uppercase;
    }
  }
}

h2 {
  &.heading-h2 {
    margin-top: -8px;
    font-size: 1.2rem;
  }
}


p {
  font-size: var(--mainFontSize);
}

.error-text {
  color: var(--accentSecondary);
  text-align: left;
  font-size: 0.8rem;
}

.name-text{
  font-weight: 700;
  font-size: 1.3rem;
}

.success-text {
  color: var(--limeGreen);
  text-align: left;
  font-size: 0.8rem;
}

.text-uppercase-bold {
  text-transform: uppercase;
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.left-text {
  text-align: left;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
}
.free_day_pass_header_text{
  font-size: 1.5rem;
  margin-top: 0px;
}
.racing_tv_text{
  font-size: 1.2rem;
}
.racing-heading{
  display: flex;
  justify-content: center;
  text-align: center;
  color: #FFC324; 
  margin: 0px;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 700;
  @media only screen and (max-width: 430px) {
    font-size: 1.2rem;
  }
  @media only screen and (max-width: 385px) {
    font-size: 1rem;
  }
}

.racecourse-text{
  font-size: 1.1rem;
  margin-top: 0px;
  font-weight: bold;
  @media only screen and (max-width: 430px) {
    font-size: 0.9rem;
  }
  @media only screen and (max-width: 385px) {
    font-size: 0.8rem;
  }
}

.m-0{
  margin: 0;
}

.col {
  flex-direction: column;
}

.accent {
  color: var(--accent);
}

.accent-secondary {
  color: var(--accentSecondary);
}

.mb-2{
  margin-bottom: 2px;
}

@media only screen and (min-width: 768px){
  .expand-text{
    font-size: 30px;
    svg{
      height: 3rem !important;
      width: 15rem !important;
    }
  }
}

@media only screen and (max-width: 768px){
  .expand-text{
    font-size: 17px;
    svg{
      height: 1.3rem !important;
      width: 9rem !important;
    }
  }
}

