@import url("../theme.css");

.alert-container {
  line-height: 1.2;
  padding: 0.8em;
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
  border-radius: 8px;
  margin-bottom: 0.5em;
  .alert-header {
    display: flex;
    align-items: center;
    height: 2em;
    font-size: 1.1rem;
    font-weight: 500;
    height: 30px;

    svg {
      margin-right: 5px;
    }
  }

  .alert-link {
    // text-decoration: none;
    color: #004085;
    font-weight: 700;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
