.loader {
  position: relative;
  margin: 0px auto;
  width: 100px;
}

.loader.inline {
  max-width: 25px;
}

.loader:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.circular {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

.loader.accent-contrast {
  stroke: var(--accentContrast);
}

.loader.accent-secondary-contrast {
  stroke: var(--accentSecondaryContrast);
}

@-webkit-keyframes
rotate {  100% {
 -webkit-transform: rotate(360deg);
 transform: rotate(360deg);
}
}

@keyframes
rotate {  100% {
 -webkit-transform: rotate(360deg);
 transform: rotate(360deg);
}
}

@-webkit-keyframes
  dash {  0% {
 stroke-dasharray: 1, 200;
 stroke-dashoffset: 0;
}
 50% {
 stroke-dasharray: 89, 200;
 stroke-dashoffset: -35;
}
 100% {
 stroke-dasharray: 89, 200;
 stroke-dashoffset: -124;
}
}

@keyframes
dash {  0% {
 stroke-dasharray: 1, 200;
 stroke-dashoffset: 0;
}
 50% {
 stroke-dasharray: 89, 200;
 stroke-dashoffset: -35;
}
 100% {
 stroke-dasharray: 89, 200;
 stroke-dashoffset: -124;
}
}

@-webkit-keyframes
color {  100%, 0% {
 stroke: var(--accent);
}
 40% {
 stroke: var(--accent);
}
 66% {
 stroke: var(--accent);
}
 80%, 90% {
 stroke: var(--accent);
}
}
