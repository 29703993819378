.lozenge-container {
  background-color: var(--accent);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  padding-inline: 0.5em;
  margin-left: 0.5em;
  font-weight: bold;
  text-transform: uppercase;
}


