.droptext-container {
  margin-top: -10px;
  padding-top: 1rem;
}

.droptext-input {
  margin-left: 2rem;
}

.label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.input-and-button {
  display: flex;
  align-items: flex-end;
}

.space-left {
  margin-left: 1rem;
}

.rotate{
  -moz-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.rotate.down{
  -ms-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}


.slide-bottom{-webkit-animation:slide-bottom .2s linear both;animation:slide-bottom .2s linear both}


/**
 * ----------------------------------------
 * animation slide-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
            opacity: 0.5;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
            opacity: 1;
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
            opacity: 0.5;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
            opacity: 1;
  }
}
