@import url("../theme.css");

.success-message-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  max-width: 100%;
  border-radius: 12px;
  padding: 0px 15px 0px 15px;
  border: 1px solid var(--gray);
  margin-top: 0.5rem;

  .success-message-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 80%;
    text-align: center;
    padding-bottom: 0.5em;

    h1 {
      margin: auto;
    }

    p {
      font-size: 1.2rem;
      margin: 0;
    }

    .success {
      font-size: 1.4rem;
      font-weight: 700;

      &.accent {
        color: var(--accent);
      }

      &.accent-secondary {
        color: var(--accentSecondary);
      }
    }
  }

  .success-tick-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 2rem;
      height: 2rem;
      background-color: var(--accent);
      border-radius: 100%;
      outline: none;
      border: none;
    }

    &.accent {
      path {
        fill: white;
      }
    }

    &.accent-secondary {
      path {
        color: var(--accentSecondary);
      }
    }
  }
}
