html {
  background-color: var(--brandPrimary);
  width: 100vw;
}

html, body {
  max-width: 440px;
  margin: auto;
  overflow-x: hidden;
  line-height: 1.3;
}

.space-top {
  margin-top: 1.5rem !important;
}

.pinned-bottom {
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}
