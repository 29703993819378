@import url("../theme.css");

.radio-group-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events:none;

  &.row {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
}

.radio-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  -webkit-box-shadow: 4px 5px 8px -1px rgba(0, 0, 0, 0.55);
  box-shadow: 4px 5px 8px -1px rgba(0, 0, 0, 0.55);
  border: 2px solid #c3a71b;
  overflow: hidden;
  height: 100%;
  pointer-events:auto;

  &.small {
    height: 45px;
    width: 43%;
    border-radius: 5px;
    border-width: 1px;
  }

  &.small.selected {
    border-color: var(--accent);
    background-color: var(--radioButtonSmallSelectedBackgroundColor);
  }

  &.large {
    width: 100%;
    border-radius: 20px;
    padding: 1em;
    min-height: 100px;
    max-height: 100px;
  }

  &.selected {
    filter: brightness(100%);
    border-width: 2px;
  }

  &.brand {
    background-color: var(--brandPrimary);
  }

  &.accent.selected {
    animation: accentSelected 4s ease alternate infinite;
  }

  &.accent-secondary.selected {
    animation: accentSecondarySelected 4s ease alternate infinite;
  }

  .discount-pattern {
    width: 50vw;
    height: 100%;
    min-width: 1px;
    max-width: 50%; // stops it crossing over the price
    position: absolute;
    border-radius: 0 34px 0 0;
    right: 10px;
    transform: skewX(335deg);

    &.accent {
      background-color: var(--accent);
    }

    &.accent-secondary {
      background-color: var(--accentSecondary);
    }
  }

  .large-radio-btn-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    z-index: 2; // so it covers any discount pattern
    height: 100%;
  }

  .large-top-text-container {
    color: var(--white);
    display: flex;
    flex-direction: row;
    -webkit-user-select: none;
    user-select: none;
    text-align: start;
    width: 100%;
    margin-bottom: 10px;
    @media only screen and (max-width: 420px) {
      margin-bottom: 5px;
    }
  }

  .large-bottom-text-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  .radio-btn-left-lower-text-container {
    display: flex;
    align-items: center;
    width: 30%;
  }

  .radio-btn-title {
    font-size: 1.3rem;
    font-weight: 700;
    text-transform: uppercase;
    @media only screen and (max-width: 420px) {
      font-size: 1rem;
    }
  }

  .radio-btn-price {
    font-size: var(--radioButtonTitleTextSize);
    font-weight: 700;
    margin-right: 0.2rem;
    @media only screen and (max-width: 420px) {
      font-size: 1.4em;
    }

    &.accent {
      color: #c7a914;
    }

    &.accent-secondary {
      color: var(--accentSecondary);
    }
  }

  .radio-btn-time-length {
    line-height: 0.9;
    padding-top: -2px;
    display: inline-block;
    max-width: 5vw;
    font-size: var(--inpuLabelTextSize);
    @media only screen and (max-width: 420px) {
      font-size: 0.7em;
    }
  }

  .radio-btn-right-text {
    color: var(--white);
    font-weight: 500;
    font-size: 0.9rem;
    user-select: none;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 123px;
    text-align: start;
    width: 70%;
    span {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;  
    }
    @media only screen and (max-width: 280px) {
      font-size: 0.7em;
    }
  }

  .discount-bg {
    background-color: var(--accent);
    min-height: 100px;
    max-height: 123px;
    display: flex;
    align-items: center;
    padding: 1rem;
  }

  .discount-text {
    font-size: 16px;
  }

  .wrap-text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .radio-btn-middle-text {
    color: var(--white);
    font-weight: 700;
    font-size: var(--mainFontSize);
    user-select: none;
    text-transform: capitalize;
  }
}

.radio-group-container:hover > .radio-btn-container {
  filter: brightness(50%);
  border-color: grey;
}

.radio-group-container .radio-btn-container:hover {
  filter: brightness(125%);
  border-color: #c3a71b;
}

.notSelected {
  filter: brightness(50%);
  border-color: grey;
}

.free-day-pass-package {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 10px;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  min-height: 50px;
  max-height: 50px;
  background: none;
  border: none;
  color: var(--white);

  label {
    font-size: 0.5rem;
    margin-left: 0.5rem;
    text-align: start;
    padding-top: 4px;

    @media only screen and (min-width: 430px) {
      font-size: 0.7rem;
      padding-top: 2px;
    }
  }

}

@keyframes accentSelected {
  0% {
    border-color: #c6ab24;
  }

  12.5% {
    border-color: #e4c321;
  }

  25% {
    border-color: #eec90d;
  }

  37.5% {
    border-color: #d7b924;
  }

  50% {
    border-color: #c3a71b;
  }

  62.5% {
    border-color: #c4a60f;
  }

  75% {
    border-color: #dcbc18;
  }

  100% {
    border-color: #c7a914;
  }
}

@keyframes accentSecondarySelected {
  0% {
    border-color: var(--accentSecondary);
  }

  12.5% {
    border-color: #bd8633;
  }

  25% {
    border-color: #a37302;
  }

  37.5% {
    border-color: #c7a914;
  }

  50% {
    border-color: #9aa635;
  }

  62.5% {
    border-color: #bf522a;
  }

  75% {
    border-color: #c24319;
  }

  100% {
    border-color: #aced42;
  }
}