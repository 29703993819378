@import url("../theme.css");

.columns {
  display: flex;

  .input-outer-container {
    max-width: 49%;
  }
}

.align-direct-debit-fields{
  margin: auto;
  justify-content: center;
}


.row-spacer {
  width: 1em;

  &.thirty {
    width: 30px;
    margin: 4px;
  }
}

.fine-print {
  p {
    font-size: 0.7rem;
    text-align: left;
  }
}

.white-link {
  color: var(--white);
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  min-width: 5rem;
}

.accent-link {
  color: var(--accent);
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  min-width: 5rem;
}

.accent-secondary-link {
  color: var(--accentSecondary);
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  min-width: 5rem;
}

.signup-header {
  text-align: center;
}

hr {
  border-top: 1px solid grey;
  &.thin {
    min-width: 5rem;
    margin-left: 30%;
    margin-right: 30%;
  }

  &.wide {
    margin-left: 5%;
    margin-right: 5%;
  }
}

.d-flex{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 10px;
  .logo{
    max-width: 40px;
    .eir-logo{
      width: 3rem;
      height: 2rem;
    }
  }
  p {
    margin-left: 2rem;
    text-align: start;
    font-size: 0.7rem;
    margin-top: 4px;
  }
}
.free-day-pass-login{
  display: flex;
  justify-content: space-between;
  margin: 0px;
  padding: 0px;
  h1{
    margin: 0px;
    margin-right: 1rem;
  }
  .x-small{
    border-radius: 6px;
    margin: 0px;
    padding: 0.5rem 0.5rem;
    height: 26px;
    span{
      font-size: 1rem;
    }
  }
  .wide-free-day-pass {
    margin: 10px 5%;
  }
}
