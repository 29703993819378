/* default variables */
:root {
  --accent: #e1087e;
  --accentContrast: white;
  --accentSecondary: #f7c210;
  --accentSecondaryContrast: #121224;
  --radioButtonBackgroundColor: #060620;
  --radioButtonTitleTextSize: 2em;
  --radioButtonSmallSelectedBackgroundColor: #1d1e3a;
  --transparent: transparent;
  --white: white;
  --gray: gray;
  --limeGreen: #66b158;
  --buttonTextColor: #121224;
  --inputBackgroundColor: #c1c1c6;
  --inputTextColor: #162b69;
  --brandPrimary: #221d35;
  --mainFontSize: 1.2rem;
  --subtleFontSize: 0.8rem;
  --inputLabelTextSize: 1rem;
  --inputTooltipIconSize: 1.2rem;
}

/* Remove boder */
iframe {
  border-style: none;
}
