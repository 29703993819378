.close-container {
  margin-top: 1vh;
  margin-bottom: 1vh;
  display: flex;
  svg{
    height: 1.8rem;
  }
  .cross {
    cursor: pointer;
    width: 2.6rem;
  }
}
.free-day-pass-journey-close-container {
  margin-top: -20px;
  margin-bottom: 0px;
  display: flex;
  svg{
    height: 1.5rem;
    margin: 0px;
  }
  .cross {
    cursor: pointer;
    width: 1.4rem;
  }
}
