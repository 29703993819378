@import url("../theme.css");

.progress-bar-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .step-count-text {
    font-size: 0.8em;
    text-align: end;
  }
  .progress-bar {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .progress-bar-line {
      position: absolute;
      width: 98%;
      z-index: 0;
      border: solid 1px var(--gray);
    }
    .progress-bar-line-active {
      position: absolute;
      z-index: 1;
      border: solid 1px var(--white);
    }
    .progress-bar-dot {
      width: 6px;
      height: 6px;
      background-color: var(--gray);
      border-radius: 50%;
      z-index: 2;
      &.active {
        background-color: var(--white);
      }
    }
  }
}
