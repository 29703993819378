h1 svg {
  margin-left: 1rem;
}

h1
 span {
   &.change-button {
    float: right;
    color: white;
    font-size: var(--inputTooltipIconSize);
    padding-top: 0.1rem;
    cursor: pointer;
    font-weight: bold;
  }
}

.terms{
  p {
    text-align: justify;
    font-size: 14px;
  }

  .link {
    font-weight: bold;
    cursor: pointer;
    margin-left: 4px;
    margin-right: 2px;
  }
}