@import url("../theme.css");

.checkboxes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 0.2rem;
}

.checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 0.2rem;
  &.reverse {
    flex-direction: row-reverse;
  }
  &.short {
    align-items: center !important;
  }
  &.no-margin-bottom {
    margin-bottom: 0;
  }
  input[type="checkbox"] {
    min-width: 15px;
    min-height: 10px;
  }
  .checkbox-label {
    margin-left: 0.5rem;
    text-align: start;
    font-size: 0.7rem;
  }
}


.sky-q-checkbox-container {
  display: flex;
  border-radius: 10px;
  height: 40px;
  padding-left: 10px;
  margin-top: 0.5rem;
  .checkbox-label {
    font-weight: 700;
  }

  &.checked-true {
    background-color: var(--accent);
  }
}
