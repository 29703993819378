@import url("../theme.css");

.input-outer-container {
  padding: .3rem .0rem 0.3rem .0rem;
  width: 100%;
}

.input-label-container {
  display: flex;
  justify-content: flex-start;
  padding: 0.3rem 0.8rem 0.3rem 0rem;
  font-size: var(--inputLabelTextSize);
}
.search-field-label{
  font-weight: 700;
}
.input-container {
  height: 1.2rem;
  display: flex;
  flex-direction: row;
  filter: brightness(120%);
  padding: 0.5rem;
  border-radius: 5px;
  align-items: center;
  position: relative;
  background-color: var(--inputBackgroundColor);
  .input-tooltip {
    .input-tooltip-icon {
      cursor: help;
      font-size: var(--inputTooltipIconSize);
    }
    .input-tooltip-text {
      display: none;
      position: absolute;
      color: var(--white);
      padding: 0.5rem;
      border-radius: 5px;
      right: 0;
      width: 80%;
      text-align: end;
      font-size: var(--inputLabelTextSize);
      margin-top: 0.4rem;
    }
    &:hover .input-tooltip-text {
      display: block;
    }
  }
  .input-text-area {
    flex: 1;
    height: 100%;
    border: none;
    font-size: var(--inputLabelTextSize);
    background-color: var(--transparent);
    color: var(--inputTextColor);
    text-overflow: ellipsis;
    &:focus {
      outline: none;
    }
    &::placeholder {
      opacity: 0.8;
    }
  }
  .field-icon {
    float: right;
    position: relative;
    z-index: 2;
    color: #707689;
    cursor: pointer;
  }
  
  &.container-color{
    background-color: #B7B7BB;
    filter: brightness(100%);
    .input-text-area::placeholder{
      color: #707689;
    }
  }
}

.subtle {
  font-size: var(--subtleFontSize);
}

/* Overrides default autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--inputTextColor);
  -webkit-box-shadow: 0 0 0px 1000px #c1c1c6 inset;
  box-shadow: 0 0 0px 1000px #c1c1c6 inset;
}
